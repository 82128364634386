import React from 'react';

export default function ResultLine(props) {
  const { data } = props;
  return (
    <a className="result-line" href={data.externalUrl || ''} target="_blank" rel="noreferrer">
      <p className="year">{data.date.substring(0, 4)}</p>
      <p className="result-title">{data.title}</p>
    </a>
  );
}
